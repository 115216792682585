<template>
  <v-fade-transition>
    <v-dialog content-class="startBtnDialog" v-model="visibility" persistent max-width="25vw">
      <v-img contain :src="require('@/assets/images/buttons/start.svg')" @click="startSystem"></v-img>
    </v-dialog>
  </v-fade-transition>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'StartBtnDialog',
  computed: {
    ...mapState(['system']),
    visibility() {
      return !this.system.init
    }
  },
  methods: {
    ...mapMutations(['SET_SYSTEM_INIT']),
    startSystem() {
      this.SET_SYSTEM_INIT(true)
    }
  }
}
</script>

<style>
.startBtnDialog {
  max-width: 30vw !important;
}
</style>
