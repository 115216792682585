<template>
  <v-img style="height: 10vh" :src="image" />
</template>

<script>
import api from '@/apis'

export default {
  name: 'ExchangeRate',
  data() {
    return {
      datetime: null,
      rate: null
    }
  },
  computed: {
    image() {
      if (this.datetime && this.rate)
        return (
          'data:image/svg+xml;charset=utf-8,' +
          encodeURIComponent(`<svg width="1920" height="100" viewBox="0 0 1920 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="1920" height="100" fill="black" fill-opacity="0.65"/>
<text fill="white" xml:space="preserve" style="white-space: pre" font-family="Open Sans" font-size="28" letter-spacing="0px"><tspan x="431" y="49.3623">&#x532f;&#x7387; Currency Rate &#10;</tspan></text>
<text fill="white" xml:space="preserve" style="white-space: pre" font-family="Open Sans" font-size="18" font-weight="bold" letter-spacing="0px"><tspan x="587.955" y="74.3623"> </tspan></text>
<text fill="white" xml:space="preserve" style="white-space: pre" font-family="Open Sans" font-size="18" letter-spacing="0px"><tspan x="431" y="74.3623">(${this.datetime})</tspan></text>
<text fill="white" xml:space="preserve" style="white-space: pre" font-family="Open Sans" font-size="56" letter-spacing="0px"><tspan x="710.125" y="71.7246">1 USD &#x7f8e;&#x5143; = ${this.rate} HKD &#x6e2f;&#x5143; </tspan></text>
</svg>`)
        )
      else
        return (
          'data:image/svg+xml;charset=utf-8,' +
          encodeURIComponent(`<svg width="1920" height="85" viewBox="0 0 1920 85" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="1920" height="85" fill="black" fill-opacity="0.65"/>
</svg>`)
        )
    }
  },
  methods: {
    async updateExchangeRate() {
      try {
        const exchange_rate_result = await api.exchange_rate_query()
        if (exchange_rate_result.data.success) {
          const { datetime, rate } = exchange_rate_result.data.data
          this.datetime = datetime
          this.rate = rate
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  mounted() {
    this.updateExchangeRate()
    setInterval(() => this.updateExchangeRate(), 60000)
  }
}
</script>
