<template>
  <v-row style="width: 130vw">
    <div style="flex: 0 0 10%" v-for="(ticket, index) in tickets" :key="index">
      <v-card rounded="0" height="90%" flat class="mx-4">
        <!-- Ticket Code -->
        <v-row no-gutters justify="center">
          <v-col cols="12">
            <div class="text-center codeText">{{ ticket.code }}</div>
          </v-col>
        </v-row>

        <!-- Ticket Counter Info -->
        <v-row no-gutters>
          <v-col cols="12">
            <div class="text-center counterText">{{ window[index].lang }}</div>
            <div class="text-center counterText">{{ window[index].english }}</div>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'TicketListCard',
  props: {
    tickets: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState(['socket']),
    window_list() {
      return this.socket.window_list
    },
    window() {
      return this.tickets.map(ticket => {
        const target_window = this.window_list.find(window => window.window_id === ticket.window_id)
        if (ticket.language === 'Mandarin') return { lang: target_window.name_cn, english: target_window.name_en }
        else return { lang: target_window.name, english: target_window.name_en }
      })
    }
  }
}
</script>

<style scoped>
.codeText {
  margin-top: 1.5rem;
  font-size: 4rem;
  color: #d31145;
  letter-spacing: 4px;
}

.counterText {
  font-size: 2rem;
  color: #e4708f;
  letter-spacing: 2px;
}

@media only screen and (max-width: 960px) {
  .codeText {
    font-size: 2rem;
    margin-top: 0.5rem;
  }

  .counterText {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 920px) {
  .codeText {
    font-size: 1.95rem;
  }
}

@media only screen and (max-width: 900px) {
  .codeText {
    font-size: 1.8rem;
  }

  .counterText {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 880px) {
  .codeText {
    font-size: 1.75rem;
  }

  .counterText {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 860px) {
  .codeText {
    font-size: 1.65rem;
  }

  .counterText {
    font-size: 0.8rem;
  }
}
</style>
