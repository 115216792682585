<template>
  <v-card color="#d31145" height="100%" rounded="0" flat :class="{ callingContainer: isCalling, mapContainer: isMap }">
    <v-row no-gutters align="center" justify="center">
      <v-img :src="require('@/assets/images/title2.svg')" contain :class="{ callingImgTitle: isCalling, mapImgTitle: isMap }"></v-img>
    </v-row>
    <v-row no-gutters align="center" justify="center" style="height: 50%">
      <v-col cols="12">
        <div class="text-center" :class="{ callingTitleText: isCalling, mapTitleText: isMap }">{{ counter_name }}</div>
        <div class="text-center" :class="{ callingTitleText: isCalling, mapTitleText: isMap }">{{ counter_name_en }}</div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'CounterCard',
  data() {
    return {
      counter_name: '',
      counter_name_en: ''
    }
  },
  props: {
    mode: {
      type: String,
      default: 'calling'
    },
    ticket: {
      type: Object
    }
  },
  computed: {
    ...mapState(['socket']),
    isCalling() {
      return this.mode === 'calling'
    },
    isMap() {
      return this.mode === 'map'
    },
    window_list() {
      return this.socket.window_list
    }
  },
  watch: {
    ticket: {
      deep: true,
      handler() {
        const window_info = this.window_list.find(window => window.window_id === this.ticket.window_id)
        this.counter_name = window_info.name
        this.counter_name_en = window_info.name_en
      }
    }
  }
}
</script>

<style scoped>
.callingContainer {
  padding-top: 3.5rem;
}

.mapContainer {
  padding-top: 3.5rem;
}

.callingImgTitle {
  height: 8rem;
  margin-bottom: 2rem;
}

.mapImgTitle {
  height: 6rem;
  margin-bottom: 1rem;
}

.callingTitleText {
  color: white;
  font-size: 6rem;
}

.mapTitleText {
  color: white;
  font-size: 4rem;
}

@media only screen and (max-width: 960px) {
  .callingContainer {
    padding-top: 2.5rem;
  }

  .callingImgTitle {
    height: 5.5rem;
    margin-bottom: 1rem;
  }

  .mapImgTitle {
    height: 3rem;
  }

  .callingTitleText {
    font-size: 4rem;
  }

  .mapTitleText {
    font-size: 2rem;
  }
}
</style>
