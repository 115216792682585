<template>
  <v-container fluid class="pa-0">
    <StartBtnDialog />

    <!-- Calling Ticket Page -->
    <v-slide-x-transition>
      <v-card height="100vh" rounded="0" v-show="mode === 'calling'">
        <v-card height="90vh" rounded="0" style="border: 5px solid #d31145">
          <v-row no-gutters style="height: 65vh; border-bottom: 5px solid #d31145">
            <v-col cols="6">
              <NumberCard :mode="mode" :ticket="calling_ticket" />
            </v-col>
            <v-col cols="6">
              <CounterCard :mode="mode" :ticket="calling_ticket" />
            </v-col>
          </v-row>
          <CallHistoryCard />
        </v-card>
        <ExchangeRate />
      </v-card>
    </v-slide-x-transition>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import NumberCard from '@/components/cards/NumberCard'
import CounterCard from '@/components/cards/CounterCard'
import CallHistoryCard from '@/components/cards/CallHistoryCard'
import StartBtnDialog from '@/components/dialogs/StartBtnDialog'
import ExchangeRate from '@/components/widgets/ExchangeRate'

export default {
  name: 'TV',
  components: { NumberCard, CounterCard, CallHistoryCard, StartBtnDialog, ExchangeRate },
  data() {
    return {
      mode: 'calling',
      audio: new Audio(),
      calling_ticket: {},
      play_count: 0,
      is_audio_ended: true
    }
  },
  computed: {
    ...mapState(['socket']),
    calling_tickets() {
      return this.socket.calling_tickets
    }
  },
  methods: {
    ...mapMutations(['REMOVE_CALLING_TICKET']),
    // Play Audio and set ticket to display
    playAudio(ticket, audio_url, delay) {
      setTimeout(() => {
        this.calling_ticket = ticket
        this.audio.src = audio_url
        this.audio.play().catch(e => console.log(e.message))
      }, delay)
    }
  },
  watch: {
    calling_tickets: {
      deep: true,
      immediate: true,
      handler() {
        const autoPlay = setInterval(() => {
          if (this.calling_tickets.length) {
            const ticket = this.calling_tickets[0]
            if (this.is_audio_ended) {
              this.is_audio_ended = false
              if (this.play_count === 0) {
                this.playAudio(ticket, ticket.audio, ticket.delay * 1000)
                this.audio.onended = () => {
                  this.is_audio_ended = true
                  this.play_count++
                }
              } else if (this.play_count === 1) {
                this.playAudio(ticket, ticket.audio, 2)
                this.audio.onended = () => {
                  setTimeout(() => {
                    this.is_audio_ended = true
                    this.play_count--
                    this.REMOVE_CALLING_TICKET()
                  }, 3000)
                }
              }
            }
          } else {
            clearInterval(autoPlay)
          }
        }, 1000)
      }
    }
  },
  created() {
    setInterval(() => {
      // Auto-Connect to queue socket server if user logged in and conduct registration
      if (this.$socket.disconnected) {
        this.$socket.connect()
        setTimeout(() => {
          if (this.$socket.connected) {
            this.$socket.emit('register', { token: process.env.VUE_APP_QUEUE_SOCKET_SERVER_REGISTER_TOKEN })
          }
        }, 3000)
      }
    }, 5000)
  }
}
</script>
