<template>
  <v-card height="95%" rounded="0" flat :class="{ callingContainer: isCalling, mapContainer: isMap }">
    <v-row no-gutters align="center" justify="center">
      <v-img :src="require('@/assets/images/title1.svg')" contain class="img_title"></v-img>
    </v-row>
    <v-row no-gutters align="center" justify="center" style="height: 50%">
      <v-col cols="12">
        <div class="text-center" :class="{ callingTitleText: isCalling, mapTitleText: isMap }" v-show="!blinking">{{ code }}</div>

        <div class="text-center" :class="{ callingTitleText: isCalling, mapTitleText: isMap }" v-show="blinking">
          <VueBlink>{{ code }}</VueBlink>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import VueBlink from 'vue-blink'
export default {
  name: 'NumberCard',
  components: { VueBlink },
  data() {
    return {
      blinking: false
    }
  },
  props: {
    mode: {
      type: String,
      default: 'calling'
    },
    ticket: {
      type: Object
    }
  },
  computed: {
    isCalling() {
      return this.mode === 'calling'
    },
    isMap() {
      return this.mode === 'map'
    },
    code() {
      return this.ticket.code || ''
    }
  },
  watch: {
    ticket: {
      deep: true,
      handler() {
        this.blinking = true
        setTimeout(() => {
          this.blinking = false
        }, 10000)
      }
    }
  }
}
</script>

<style scoped>
.callingContainer {
  padding-top: 5rem;
  background-image: url('../../../assets/images/bg.svg');
  background-size: auto;
  background-position: center;
}

.mapContainer {
  padding-top: 5rem;
  background-image: url('../../../assets/images/bg.svg');
  background-size: auto;
  background-position: center;
}

.img_title {
  height: 6rem;
}

.callingTitleText {
  color: #d31145;
  font-size: 10rem;
  margin-top: 5vh;
}

.mapTitleText {
  color: #d31145;
  font-size: 6rem;
}

@media only screen and (max-width: 960px) {
  .callingContainer {
    padding-top: 4rem;
  }

  .mapContainer {
    padding-top: 2rem;
    background-size: 10rem;
  }

  .img_title {
    height: 4rem;
  }

  .callingTitleText {
    font-size: 5rem;
  }

  .mapTitleText {
    font-size: 3rem;
  }
}
</style>
