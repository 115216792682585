<template>
  <v-row no-gutters style="height: 25vh">
    <v-col cols="2" class="pa-4">
      <v-img :src="require('@/assets/images/title3.svg')" contain width="12vw"></v-img>
    </v-col>

    <!-- Static Ticket Called History -->
    <v-col cols="2" v-for="(ticket, index) in first_five_tickets" :key="index">
      <TicketCard :ticket="ticket" />
    </v-col>

    <!-- DynamicMarquee Ticket Called History -->
    <v-col cols="10" v-show="first_ten_tickets.length">
      <DynamicMarquee direction="row" reverse :hoverPause="false" :repeat="true">
        <TicketListCard :tickets="first_ten_tickets" />
      </DynamicMarquee>
    </v-col>
  </v-row>
</template>

<script>
import DynamicMarquee from 'vue-dynamic-marquee'
import TicketCard from '@/components/cards/TicketCard'
import TicketListCard from '@/components/cards/TicketListCard'
import { mapState } from 'vuex'

export default {
  name: 'CallHistoryCard',
  components: { DynamicMarquee, TicketCard, TicketListCard },
  computed: {
    ...mapState(['socket']),
    called_list() {
      return this.socket.called_list.slice(1, this.socket.called_list.length)
    },
    first_five_tickets() {
      if (this.called_list.length <= 5) {
        return this.called_list
      }
      return []
    },
    first_ten_tickets() {
      // Only return if tickets more than 5
      if (this.called_list.length > 5) {
        if (this.called_list.length > 10) {
          return this.called_list.slice(0, 10)
        }
        return this.called_list
      }
      return []
    }
  }
}
</script>
